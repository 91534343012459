<template>
    <div class="main-header mt-0 mt-md-1">
        <v-row no-gutters class="mx-0 mx-md-10 py-2 rounded-lg back-header shadow-lg" justify="space-between">
            <v-row no-gutters>
                <div class="ml-6 ml-md-0 hidden-lg-and-up"></div>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="rgb(87 75 113)" size="30" v-bind="attrs" v-on="on" class="icon-menu ml-10 ml-md-3" @click="userPanel()">multiple_stop</v-icon>
                    </template>
                    <p class="text-center mb-0">Cambiar al</p>
                    <p class="text-center mb-0">Panel de usuario</p>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="rgb(87 75 113)" size="30" v-bind="attrs" v-on="on" class="icon-menu ml-3 mb-1">mdi-account-arrow-right</v-icon>
                    </template>
                    <span>Impersonar</span>
                </v-tooltip>
            </v-row>

            <v-row no-gutters justify="end" class="col-6 col-md-8">
                <div class="mr-3">
                    <p class="my-0 name">
                        {{ formatName(user.first_name, user.last_name) }}
                    </p>
                    <p class="my-0 text-right role">
                        Administrador
                    </p>
                </div>

                <v-badge
                    bordered
                    bottom
                    color="teal accent-3"
                    dot
                    offset-x="10"
                    offset-y="10"
                    class="mr-3"
                    v-if="user.logo_path"
                >
                    <v-avatar size="40">
                        <v-img :src="user.logo_path"></v-img>
                    </v-avatar>
                </v-badge>

                <v-badge
                    bordered
                    bottom
                    color="teal accent-3"
                    dot
                    offset-x="10"
                    offset-y="10"
                    class="mr-3"
                    v-else
                >
                    <v-avatar
                    color="black"
                    size="38"
                    >
                        <span class="white--text text-p">
                            {{ user.first_name.charAt(0)+user.last_name.charAt(0) }}
                        </span>
                    </v-avatar>
                </v-badge>
            </v-row>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data () {
        return {
            user: null,
            drawer: false
        }
    },
    created() {
        this.checkUser();
    },
    methods: {

        userPanel(){
            this.$router.push('/panel');
        },

        checkUser(){
            let user = localStorage.getItem("user");
            if (user){
                this.user = JSON.parse(user);
            }
        },
        formatName(firstName, lastName) {
            const firstPart = firstName.split(' ').slice(0, 1).join(' ');
            const lastPart = lastName.split(' ').slice(0, 1).join(' ');
            return firstPart + ' ' + lastPart;
        },
    }
};
</script>

<style scoped>
.cursor {
    cursor: pointer;
}

.name {
    color: black;
    font-size: 14px;
}

.role {
    font-size: 10px;
    color: #907db9;
}

.back-header {
    background-color: white;
    box-shadow: 0px 1px 9px -2px #a9a9a9;
}

.main-header {
    width: 100%;
    height: 64px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
</style>
